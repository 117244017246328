<template>
  <div class="myorder-view">
    <div class="acount-view-middle">
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow === 0 ? 'active' : '']"
          @click="getAccountDetails(0)">全部</a>
      </div>
      <div class="acount-view-middle-1 br ">
        <a href="javascript:;" :class="[getAccountDetailsShow === 1 ? 'active' : '']"
          @click="getAccountDetails(1)">待付款</a>
      </div>
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow === 2 ? 'active' : '']"
          @click="getAccountDetails(2)">已付款</a>
      </div>
    </div>
    <div class="acount-view-bottom" v-for="(item, index) in getOrderRecordList" :key="index">
      <div class="acount-view-bottom-order-top">
        <span class="acount-view-order-1">{{ item.auctionName }}</span>
        <span class="acount-view-order-2">拍卖行：{{ item.auctionHouseName }}</span>
        <span class="acount-view-order-2">开拍时间：{{ item.startTime }}</span>
        <span class="acount-view-order-2">订单号：{{ item.orderNo }}</span>
        <!-- <div>
          <span class="myorder-name-1">开拍时间：</span>
          <span class="myorder-name-1">{{ item.startTime }}</span>
        </div> -->
      </div>
      <div class="acount-view-bottom-order-middle">
        <div class="acount-view-bottom-order-bottom-name">
          <div class="myorder-name">
            <span class="myorder-name-1">{{ item.realName }}</span>
            <span class="myorder-name-2">{{ item.mobile }}</span>
          </div>
          <div class="myorder-loaction">
            <div class="myorder-loaction-left myorder-name-1">
              <span style="padding-right:20px">{{ item.addressDetails }}</span>
              <span>邮编：{{ item.zipCode }}</span>
            </div>
            <a class="myorder-loaction-right" @click="editLocation(item.orderNo)" v-if="item.status !== '2'">
              修改地址
            </a>
          </div>
        </div>
      </div>
      <div class="acount-view-bottom-order-bottom" v-for="(item, index) in item.list" :key="index">
        <div class="acount-view-bottom-order-img">
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{
                    path: '/productdetails',
                    query: { productdetailsId: item.itemId }
                  }">
                  <img :src="item.image" alt="" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="acount-view-bottom-order-img-right">
          <div class="dropHammerPrice">
            <router-link target="_blank" class="my-price-9" :to="{
                path: '/productdetails',
                query: { productdetailsId: item.itemId }
              }">
              Lot {{ item.lot }}：{{ item.title }}
            </router-link>
            <!-- <p class="my-price-9">Lot {{item.lot}}：{{item.title}}</p> -->
            <p class="co-n">拍品价格：{{ item.totalPrice }}</p>
            <p class="co-n">
              我的出价：{{ item.highestBid }}{{ item.currency }}
            </p>
            <p>
              <span class="co-n">落槌价格：{{ item.dropHammerPrice }}{{ item.currency }}</span>
              <a href="javascript:;" class="cda156-a-1"
                @click="bidRecord(item.itemId,item.status,item.attrAuctionWayId)">出价记录</a>
            </p>
          </div>
          <div class="qx-or-wy">
            <span style="color:#cd5656;" v-if="item.status === '5'">已违约</span>
            <span style="color:#999;" v-else-if="item.status === '4'">已取消</span>
          </div>
        </div>
      </div>
      <div class="acount-view-bottom-order-count">
        <button class="jsmx-count jsmx-count-coc" v-if="
            getAccountDetailsShow === 2 ||
              item.status === '2' ||
              item.status === '5' ||
              item.status === '4'
          " disabled>
          已支付
        </button>
        <a href="javascript:;" class="jsmx-count" v-else @click="
            immediatePayment(
              item.auctionId,
              item.orderNo,
              item.addressId,
              item.actualPayment,
              item
            )
          ">立即支付</a>
        <p class="count-price-2">
          <span class="hj333">合计：</span>
          <span class="hj-rmb">{{ item.actualPayment }}&nbsp;</span>
          <span class="hj333">元</span>
        </p>
        <a href="javascript:;" class="jsmx-price" @click="onItemized(item.orderNo)">结算明细</a>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="getOrderRecordData.page"
        :page-size="getOrderRecordData.pageSize" @current-change="handlePageChange">
      </el-pagination>
    </div>
    <Bidrecord ref="bidrecord" :bidRecordId="bidRecordId" :bidStatus="bidStatus" :bidWayId="bidWayId" />
    <el-dialog title="支付信息" :visible.sync="dialogVisible" width="30%">
      <div class="dialog-1">
        <span>落槌价</span>
        <span>{{ getImmediatePaymentLd.newDropHammerPrice
          }}{{ getImmediatePaymentLd.currency }}</span>
      </div>
      <div class="dialog-1">
        <span>买家佣金</span>
        <span>{{ getImmediatePaymentLd.buyersPremium
          }}{{ getImmediatePaymentLd.currency }}</span>
      </div>
      <div class="dialog-1">
        <span>平台服务费</span>
        <span>{{ getImmediatePaymentLd.platformServiceFee
          }}{{ getImmediatePaymentLd.currency }}</span>
      </div>
      <div class="dialog-1">
        <span>税费</span>
        <span>{{ getImmediatePaymentLd.taxRate
          }}{{ getImmediatePaymentLd.currency }}</span>
      </div>
      <div class="dialog-1 border-1">
        <span>其他</span>
        <span>{{ getImmediatePaymentLd.TaxRate
          }}{{ getImmediatePaymentLd.currency }}</span>
      </div>
      <div class="dialog-1" style="margin-top:20px;">
        <span>订单总额(人民币)</span>
        <span>{{ getImmediatePaymentLd.total }}元</span>
      </div>
      <div class="dialog-1">
        <span>在线支付手续费(0.6%)</span>
        <span>{{ getImmediatePaymentLd.serviceCharge }}元</span>
      </div>
      <div class="dialog-1 border-2">
        <span>保证金抵扣</span>
        <span style="color:#cd5656">-{{ bondMoney }}元</span>
      </div>
      <div class="dialog-1 border-3">
        <span>待支付金额</span>

        <!-- <span v-if="getImmediatePaymentLd.toBePaid >0">{{getImmediatePaymentLd.toBePaid}}元</span> -->
        <span>{{ endNum }}元</span>
        <!-- <span v-else>0元</span> -->
      </div>
      <div class="dialog-big" v-if="accountStatus==0">
        <div class="dialog-left">
          <div class="dialog-1-1">
            <i class="iconfont icon-qianbao1" style="color:rgb(205, 152, 86);"></i>
            <span class="paddingRight">余额支付</span>
            <span class="font16">当前账户余额为：</span>
            <span style="color:#cda156;" class="font16">{{
              getImmediatePaymentLd.availableBalance
            }}</span>
            <span class="font16">元</span>
            <a href="javascript:;" class="paddingLeft" @click="czPM">充值</a>
          </div>
          <div class="dialog-1-1" v-if="toBePaidShow">
            <i class="iconfont icon-weixinzhifu" style="color:rgb(0, 179, 45);"></i>
            <span>微信支付</span>
          </div>
          <div class="dialog-1-1" v-if="toBePaidShow">
            <i class="iconfont icon-zhifubao" style="color:rgb(0, 162, 234);"></i>
            <span>支付宝支付</span>
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="radio" @change="wxPay($event)">
            <el-radio label="1"></el-radio>
            <el-radio label="2" v-if="toBePaidShow"></el-radio>
            <el-radio label="3" v-if="toBePaidShow"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="dialog-1-text">
        <p class="biy-text">
          <i class="iconfont icon-tishishuoming"></i>
        </p>
        <div class="biy-text-2">
          <span class="biy-text">您也可以支付订单总额到焱十一的银行账户，免除在线支付手续费；支付完成后，保证金将解冻到您的账户可用余额。</span>
          <span> 公司名称:北京艺得在线科技有限公司</span>
          <span>银行账号:171036255</span>
          <span>开户行名称:中国民生银行股份有限公司北京陶然桥支行</span>
          <span>付款备注：请备注您的订单号</span>
          <span style="padding-top:20px">如需协助，请联系4006169988</span>
        </div>
      </div>
      <div class="payCode" v-if="radio === '2' || radio === '3'">
        <!-- <img :src="url" alt=""> -->
        <ewmCode ref="ewmCode" />
        <span>支付完成后请点击下方支付保证金按钮</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="accountStatus==0" type="primary" :disabled="btnMesShow" @click="payLoad()">支付订单</el-button>
        <button v-else class="jsmx-count jsmx-count-coc" disabled>支付订单</button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisibleT" width="30%">
      <span>请在新打开的页面进行充值。</span>
      <span>充值完成后，根据您的情况点击下面按钮</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rechargeSuccessful">充值成功</el-button>
        <el-button type="primary" @click="dialogVisibleT = false">充值失败</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改地址" :visible.sync="dialogVisibleS" width="50%">
      <div class="scro-dllist" style="overflow-y:scroll;height:800px">
        <a href="javascript:;" class="receivinggoods-view-bottom-list" :class="{ listactive: item.id === current }"
          v-for="(item, index) in addressListDl" :key="index" @click="receivinggoodsView(item.id, index)">
          <div class="receivinggoods-view-bottom-list-left">
            <div class="receivinggoods-view-bottom-list-1">
              <span>收货人：</span>
              <span>{{ item.realName }}</span>
              <span class="defult-location" v-if="item.isDefault">默认地址</span>
            </div>
            <div class="receivinggoods-view-bottom-list-1">
              <span>联系方式：</span>
              <span>{{ item.mobile }}</span>
            </div>
            <div class="receivinggoods-view-bottom-list-1">
              <span>收货地址：</span>
              <span>{{ item.code + item.addressDetails }}</span>
            </div>
            <div class="receivinggoods-view-bottom-list-1">
              <span>邮编地址：</span>
              <span>{{ item.zipCode }}</span>
            </div>
          </div>
          <a href="javascript:;" class="iconfont icon-bianji flex1" @click="editEceivinggoods(item.id)"></a>
        </a>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="k-h-x-j" @click="newLoaction">新建地址</button>
        <button class="k-h-x-j k-h-x-j-ok" @click="newOk">确认</button>
      </span>
    </el-dialog>
    <div class="jsmx-dialog">
      <el-dialog title="结算明细" width="31.3%" :visible.sync="dialogTableVisible">
        <div class="itemized">
          <div class="text">
            <span>拍卖会名称：</span>
            <span>{{ getBillingDetailsData.auctionName }}</span>
          </div>
          <div class="text">
            <span>拍卖行名称：</span>
            <span>{{ getBillingDetailsData.auctionHouseName }}</span>
          </div>
          <div class="text itemized-start-time">
            <span>开 拍 时 间：</span>
            <span>{{ getBillingDetailsData.startTime }}</span>
          </div>
          <div class="middle">
            <div class="middle-top" v-for="(item, index) in getBillingDetailsData.list" :key="index">
              <div class="middle-top-1">
                <router-link target="_blank" :to="{
                    path: '/productdetails',
                    query: { productdetailsId: item.itemId }
                  }" class="text-1">Lot {{ item.lot }}：{{ item.title }}
                </router-link>
                <span class="block" v-if="item.status === '5'">已违约</span>
                <span class="block" v-if="item.status === '4'">已取消</span>
              </div>
              <div class="middle-top-2">
                <div class="middle-img">
                  <img :src="item.image" alt="" />
                </div>
                <div class="middle-txt">
                  <p>
                    <span>落槌价格：</span>
                    <span>{{ format(item.dropHammerPrice) }} {{ item.currency }}</span>
                  </p>
                  <p>
                    <span>买家佣金：</span>
                    <span>{{ format(item.buyersPremium) }} {{ item.currency }}</span>
                  </p>
                  <p>
                    <span>成交价格：</span>
                    <span>{{format(item.totalPrice)}}元</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <p>
              <span>落槌价</span>
              <span>{{ format(getBillingDetailsDetails.DropHammerPrice)
                }}{{ getBillingDetailsDetails.currency }}</span>
            </p>
            <p>
              <span>买家佣金</span>
              <span>{{ format(getBillingDetailsDetails.BuyersPremium)
                }}{{ getBillingDetailsDetails.currency }}</span>
            </p>
            <p>
              <span>平台服务费</span>
              <span>{{ format(getBillingDetailsDetails.PlatformServiceFee)
                }}{{ getBillingDetailsDetails.currency }}</span>
            </p>
            <p>
              <span>税费</span>
              <span>{{ format(getBillingDetailsDetails.TaxRate)
                }}{{ getBillingDetailsDetails.currency }}</span>
            </p>
            <p>
              <span>其他</span>
              <span>{{ format(getBillingDetailsDetails.OtherPrice)
                }}{{ getBillingDetailsDetails.currency }}</span>
            </p>
          </div>
          <div class="pay-money-5">
            <span>拍品总价</span>
            <span>{{ format((getBillingDetailsDetails.DropHammerPrice+getBillingDetailsDetails.BuyersPremium+getBillingDetailsDetails.PlatformServiceFee+getBillingDetailsDetails.TaxRate+getBillingDetailsDetails.OtherPrice).toFixed(2))}}{{ getBillingDetailsDetails.currency }}</span>
          </div>
          <div class="pay-money-5">
            <span>人民币总价</span>
            <span>{{ format(getBillingDetailsDetails.Total) }}元</span>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--编辑 -->
    <Editeceivinggoods ref="editeceivinggoods" :editEceivinggoodsId="editEceivinggoodsId"
      @EditReceivinggoodsSave="EditReceivinggoodsSave" />
    <!-- 新增 -->
    <Addreceivinggoods ref="addreceivinggoods" @addReceivinggoodsSave="addReceivinggoodsSave" />
  </div>
</template>
<script>
  import {
    getOrderRecord,
    weChatRecharge,
    getImmediatePayment,
    balance,
    aliRecharge,
    weChatRefund,
    addressList,
    editOrderAddress,
    getBillingDetails,
    accountStatus
  } from '../../../api/index'
  import Bidrecord from './Bidrecord.vue'
  import Editeceivinggoods from './Editeceivinggoods.vue'
  import Addreceivinggoods from './Addreceivinggoods.vue'
  import ewmCode from '../../../components/ewmCode.vue'
  export default {
    components: {
      Bidrecord,
      Editeceivinggoods,
      Addreceivinggoods,
      ewmCode
    },
    inject: ['reload'],
    data() {
      return {
        getOrderRecordData: {
          type: 0,
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 5,
          orderNo: ''
        },
        itemIzedData: {},
        dialogTableVisible: false,
        pageTotal: 0,
        getOrderRecordList: [],
        bidRecordId: 0,
        dialogVisible: false,
        radio: 0,
        getAccountDetailsShow: 0,
        auctionId: '',
        orderNo: '',
        addressId: '',
        getImmediatePaymentLd: {},
        actualPayment: 0,
        url: '',
        dialogVisibleT: false,
        outTradeNo: '',
        dialogVisibleS: false,
        addressListDl: [],
        addressListData: {
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10
        },
        current: null,
        orderNoTwo: '',
        editEceivinggoodsId: 0,
        locationId: '',
        toBePaidShow: true,
        endNum: 0,
        bondMoney: 0,
        btnMesShow: false,
        timeSetTime: null,
        getBillingDetailsData: {},
        getBillingDetailsDetails: {},
        bidStatus: '',
        bidWayId: '',
        accountStatus:0
      }
    },
    created() {
      if (localStorage.getItem('orderNo')) {
        this.getOrderRecordData.orderNo = localStorage.getItem('orderNo')
      }
      this.getOrderRecord()
      this.getAccountDetailsList()
    },
    destroyed() {
      localStorage.removeItem('orderNo')
    },
    // filters: {
    //   sumZero (value) {
    //     console.log(value)

    //     // if (!isNaN(value)) {
    //     // }
    //   }
    // },
    methods: {
      async getAccountDetailsList() {
        const res = await accountStatus({
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
        })
        if (res.code === 0) {
          this.accountStatus = res.data.account_status
        } else {
          this.$message.error(res.msg)
        }
      },
      formatDel(num) { // 去除千分号
        const newval = num.toString().replace(/,/gi, '')
        return Number(newval)
      },
      format(num) { // 千分位
        return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
      },
      // 结算明细
      async onItemized(orderNo) {
        this.dialogTableVisible = true
        this.getBillingDetailsData = {}
        this.getBillingDetailsDetails = {}
        const res = await getBillingDetails({
          userId: Number(localStorage.getItem('accessId')),
          outTradeNo: orderNo,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.getBillingDetailsData = res.data
          if (res.data.details !== null) {
            this.getBillingDetailsDetails = res.data.details[0]
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      getAccountDetails(index) {
        this.getOrderRecordData.page = 1
        this.getOrderRecord(index)
        this.getAccountDetailsShow = index
      },
      receivinggoodsView(id, index) {
        this.current = id
        this.locationId = id
      },
      async getOrderRecord(index) {
        this.getOrderRecordData.type = index || 0
        const res = await getOrderRecord(this.getOrderRecordData)
        if (res.code === 0) {
          res.data.list.map(item => {
            item.list.map(item2 => {
              item2.attrAuctionWayId = item.attrAuctionWayId
            })
          })
          this.getOrderRecordList = res.data.list
          this.pageTotal = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      // 分页
      handlePageChange(val) {
        this.getOrderRecordData.page = val
        this.getOrderRecord(this.getAccountDetailsShow)
      },
      // 修改地址
      editLocation(orderNo) {
        this.dialogVisibleS = true
        this.addressList()
        this.orderNoTwo = orderNo
      },
      newLoaction() {
        // localStorage.setItem('informationShow', 8)
        // localStorage.setItem('goOrder', true)
        // this.$router.push({
        //   path: '/personalcenter',
        //   name: 'Personalcenter'
        // })
        // this.reload()
        this.$nextTick(() => {
          this.$refs.addreceivinggoods.open()
        })
      },
      // 修改地址确定
      async newOk() {
        if (this.locationId === '') {
          this.$message.error('请选择地址')
          return
        }
        const res = await editOrderAddress({
          userId: Number(localStorage.getItem('accessId')),
          addressId: this.locationId,
          orderNo: this.orderNoTwo,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success('修改成功')
          this.dialogVisibleS = false
          this.getOrderRecord()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 出价记录
      bidRecord(id, status, wayId) {
        this.bidRecordId = id
        this.bidStatus = status
        this.bidWayId = wayId
        this.$nextTick(() => {
          this.$refs.bidrecord.open()
        })
      },

      // 立即支付
      async immediatePayment(auctionId, orderNo, addressId, actualPayment, item) {
        if (item.addressDetails === '') {
          this.$message.error('请先填写收货地址')
          // this.dialogVisibleS = true
          return
        }
        this.radio = 0
        this.dialogVisible = true
        this.auctionId = auctionId
        this.orderNo = orderNo
        this.addressId = addressId
        this.actualPayment = actualPayment
        const res = await getImmediatePayment({
          outTradeNo: orderNo,
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          isNew: 1
        })
        this.getImmediatePaymentLd = res
        if (
          String(this.getImmediatePaymentLd.newDropHammerPrice).indexOf('.') ===
          -1
        ) {
          this.getImmediatePaymentLd.newDropHammerPrice = this.getImmediatePaymentLd.newDropHammerPrice.toFixed(
            2
          )
        }
        if (
          String(this.getImmediatePaymentLd.buyersPremium).indexOf('.') === -1
        ) {
          this.getImmediatePaymentLd.buyersPremium = this.getImmediatePaymentLd.buyersPremium.toFixed(
            2
          )
        }
        if (
          String(this.getImmediatePaymentLd.platformServiceFee).indexOf('.') ===
          -1
        ) {
          this.getImmediatePaymentLd.platformServiceFee = this.getImmediatePaymentLd.platformServiceFee.toFixed(
            2
          )
        }
        if (String(this.getImmediatePaymentLd.taxRate).indexOf('.') === -1) {
          this.getImmediatePaymentLd.taxRate = this.getImmediatePaymentLd.taxRate.toFixed(
            2
          )
        }
        if (String(this.getImmediatePaymentLd.TaxRate).indexOf('.') === -1) {
          this.getImmediatePaymentLd.TaxRate = this.getImmediatePaymentLd.TaxRate.toFixed(
            2
          )
        }
        if (String(this.getImmediatePaymentLd.total).indexOf('.') === -1) {
          this.getImmediatePaymentLd.total = this.getImmediatePaymentLd.total.toFixed(
            2
          )
        }
        if (
          String(this.getImmediatePaymentLd.serviceCharge).indexOf('.') === -1
        ) {
          this.getImmediatePaymentLd.serviceCharge = this.getImmediatePaymentLd.serviceCharge.toFixed(
            2
          )
        }
        if (String(this.getImmediatePaymentLd.bond).indexOf('.') === -1) {
          this.getImmediatePaymentLd.bond = this.getImmediatePaymentLd.bond.toFixed(
            2
          )
        }
        if (String(this.getImmediatePaymentLd.toBePaid).indexOf('.') === -1) {
          this.getImmediatePaymentLd.toBePaid = this.getImmediatePaymentLd.toBePaid.toFixed(
            2
          )
        }
        const numCount =
          Number(this.getImmediatePaymentLd.total) +
          Number(this.getImmediatePaymentLd.serviceCharge)
        if (numCount < Number(this.getImmediatePaymentLd.bond)) {
          this.bondMoney = numCount.toFixed(2)
          this.endNum = 0
          this.toBePaidShow = false
        } else {
          this.endNum = this.getImmediatePaymentLd.toBePaid
          this.bondMoney = this.getImmediatePaymentLd.bond
          this.toBePaidShow = true
        }
        // if (this.getImmediatePaymentLd.toBePaid < 0) {
        //   this.toBePaidShow = false
        // } else {
        //   this.endNum = 0
        //   this.toBePaidShow = false
        // }
      },
      base64ImgtoFile(dataurl, filename = 'file') {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const suffix = mime.split('/')[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `${filename}.${suffix}`, {
          type: mime
        })
      },
      rechargeSuccessful() {
        this.$message.success('正在查询中，请稍后...')
        var timeOne = setTimeout(() => {
          this.wxOkSuccess()
          clearInterval(timeOne)
        }, 3000)
        this.dialogVisibleT = false
        this.dialogVisible = false
      },
      async wxOkSuccess() {
        const res = await weChatRefund({
          outTradeNo: this.outTradeNo
        })
        if (res.resultCode === 0) {
          this.$message.success('充值成功')
          this.dialogVisible = false
          this.getOrderRecord()
          this.btnMesShow = false
        } else {
          this.$message.error(res.resultMsg)
          this.btnMesShow = false
        }
      },
      // 获取收货地址
      async addressList() {
        const res = await addressList(this.addressListData)
        if (res.code === 0) {
          this.addressListDl = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      // 支付按钮
      async wxPay(event) {
        const bond = Number(this.getImmediatePaymentLd.bond)
        const description =
          '3' +
          ',' +
          this.auctionId +
          ',' +
          this.orderNo +
          ',' +
          bond +
          ',' +
          this.addressId
        if (event === '2') {
          // this.radio = '2'
          // 微信支付
          const amount = Number(
            this.getImmediatePaymentLd.toBePaid * 100
          ).toFixed(2)
          const res = await weChatRecharge({
            description: description,
            amount: Number(amount),
            currency: 'CNY',
            type: 1
          })
          if (res.resultCode === 0) {
            // const img = 'data:image/png;base64,' + res.url
            this.outTradeNo = res.outTradeNo
            // const file = this.base64ImgtoFile(img) // 得到File对象
            this.$nextTick(() => {
              this.$refs.ewmCode.open(res.url)
            })
            // this.url =
            //   window.webkitURL.createObjectURL(file) ||
            //   window.URL.createObjectURL(file) // imgUrl图片网络路径
          }
        } else if (event === '3') {
          const res = await aliRecharge({
            subject: description,
            type: 1,
            totalAmount: Number(this.getImmediatePaymentLd.toBePaid) * 100,
            // returnUrl: 'https://www.test.easyebid.com/#/personalcenter?informationShow=4'
            returnUrl: 'https://www.easyebid.com/#/personalcenter?informationShow=6'
          })
          if (res.resultCode === 0) {
            this.outTradeNo = res.outTradeNo
            this.$nextTick(() => {
              this.$refs.ewmCode.open(res.url)
            })
          } else {
            this.$message.error(res.resultMsg)
          }
        }
      },
      // 支付保证金
      async payLoad() {
        if (this.radio === 0) {
          this.$message.error('请选择支付方式')
        }
        this.btnMesShow = true
        const description =
          '3' +
          ',' +
          this.auctionId +
          ',' +
          this.orderNo +
          ',' +
          this.getImmediatePaymentLd.bond +
          ',' +
          this.addressId
        if (this.radio === '1') {
          if (
            this.getImmediatePaymentLd.availableBalance === 0 ||
            this.getImmediatePaymentLd.availableBalance <
            this.getImmediatePaymentLd.toBePaid
          ) {
            this.$message.error('余额不足，请充值')
            this.btnMesShow = false
          } else {
            this.timeSetTime = setInterval(() => {
              this.btnMesShow = false
            }, 2000)
            const res = await balance({
              description: description,
              totalAmount: Number(this.getImmediatePaymentLd.toBePaid),
              bond: Number(this.getImmediatePaymentLd.bond)
            })
            if (res.resultCode === 0) {
              this.$message.success('支付成功')
              this.dialogVisible = false
              this.getOrderRecord()
            } else if (res.resultCode === 505) {
              this.$message.error(res.resultMsg)
            } else {
              this.$message.error('支付失败')
            }
          }
        } else if (this.radio === '2' || this.radio === '3') {
          this.wxOkSuccess()
        }
      },
      // 充值
      czPM() {
        sessionStorage.setItem('informationShow', 4)
        this.$router.push({
          path: '/personalcenter',
          name: 'Personalcenter',
          query: {
            fige: 1
          }
        })
        this.reload()
      },
      // 编辑
      editEceivinggoods(id) {
        this.editEceivinggoodsId = id
        this.$nextTick(() => {
          this.$refs.editeceivinggoods.open()
        })
      },
      EditReceivinggoodsSave() {
        this.addressList()
      },
      // 新增后刷新
      addReceivinggoodsSave() {
        this.addressList()
      }
    }
  }
</script>
<style lang="less" scoped>
  .myorder-view {
    width: 1400px;

    .jsmx-dialog {
      /deep/.el-dialog__header {
        padding: 20px 0 0 0;
      }

      /deep/.el-dialog__body {
        padding: 20px 40px;
      }
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    /deep/ .el-radio__input.is-checked .el-radio__inner {
      background: #cda156;
      border-color: #cda156;
    }

    /deep/ .el-radio__inner {
      border-radius: 0;
    }

    /deep/ .el-radio__inner:hover {
      border-color: #cda156;
    }

    /deep/ .ant-collapse {
      background-color: #fff;
      border: none;
      position: relative;
    }

    /deep/ .el-radio__label {
      font-size: 12px;
      color: transparent;
      padding-left: 0;
    }

    /deep/ .el-radio__input.is-checked+.el-radio__label {
      color: transparent;
    }

    /deep/ .el-radio-group {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-top: 11px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      padding-bottom: 32px;
    }

    /deep/ .el-button {
      width: 200px;
      font-weight: 600;
      font-size: 18px;
      color: #6e5121;
      opacity: 0.69;
      border-radius: 8px;
      background: #cda156;
      height: 44px;
      border: none;
    }

    /deep/ .el-dialog__footer {
      text-align: center;
    }

    /deep/ .el-dialog__header {
      text-align: center;
    }

    /deep/ .el-dialog__title {
      font-size: 24px;
      font-weight: 600;
      color: #333;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 100 !important;
    }

    .k-h-x-j {
      width: 200px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid #cda156;
      color: #cda156;
      font-weight: 600;
      line-height: 44px;
      font-size: 18px;
      margin: 0 20px;
      background: #fff;
    }

    .k-h-x-j-ok {
      background: #cda156;
      border: none;
      color: #fff;
    }

    .receivinggoods-view-bottom-list:hover {
      border: 1px solid #cda156;
    }

    .listactive {
      border: 1px solid #cda156 !important;
      background-image: url('../../../assets/images/gou.png');
      background-repeat: no-repeat;
      background-position: left top;
    }

    .receivinggoods-view-bottom-list {
      // width: 1280px;
      height: 204px;
      padding: 30px 0 32px 30px;
      border: 1px solid #dedede;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      padding-right: 50px;

      .receivinggoods-view-bottom-list-left {
        display: flex;
        flex: 1;
        flex-direction: column;

        .receivinggoods-view-bottom-list-1 {
          flex: 1;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;

          .defult-location {
            display: inline-block;
            width: 79px;
            height: 24px;
            border-radius: 3px;
            background: #cda156;
            color: #fff;
            text-align: center;
            line-height: 24px;
            margin-left: 10px;
            font-size: 14px;
          }
        }
      }

      .flex1 {
        // flex: 1;
        font-weight: 400;
        font-size: 20px;
        color: #cda156;
        text-align: center;
        line-height: 143px;
      }
    }

    .acount-view-middle {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;
      display: flex;
      padding: 20px 0;

      .acount-view-middle-1 {
        width: 200px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;

        // padding-top: 10px;
        .active {
          color: #cda156;
          border-bottom: 2px solid #cda156;
        }

        a {
          color: #666;

          &:hover {
            color: #cda156;
            border-bottom: 2px solid #cda156;
          }
        }
      }

      .br {
        border-right: 1px solid #bfc3c4;
      }
    }

    .acount-view-bottom {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;

      // padding-bottom: 20px;
      .acount-view-bottom-order-top {
        height: 141px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #dedede;
        padding-left: 20px;

        .acount-view-order-1 {
          font-size: 24px;
          font-weight: 600;
          color: #333;
          // padding-bottom: 10px;
        }

        .acount-view-order-2 {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .acount-view-bottom-order-middle {
        padding-left: 20px;
        padding-top: 15px;

        .acount-view-bottom-order-bottom-name {
          padding-bottom: 20px;
          border-bottom: 1px solid #dedede;

          .myorder-name {
            margin-bottom: 12px;

            .myorder-name-2 {
              font-size: 18px;
              color: #666;
              font-weight: 400;
            }
          }

          .myorder-name-1 {
            font-size: 20px;
            font-weight: 400;
            color: #333;
            padding-right: 10px;
          }

          .myorder-loaction {
            display: flex;
            justify-content: space-between;
            padding-right: 30px;

            .myorder-loaction-right {
              display: inline-block;
              width: 140px;
              border: 1px solid #dedede;
              opacity: 0.69;
              border-radius: 8px;
              height: 38px;
              color: #666;
              font-size: 16px;
              text-align: center;
              line-height: 38px;
            }
          }
        }
      }

      .acount-view-bottom-order-bottom {
        padding-left: 20px;
        padding-top: 29px;
        display: flex;
        padding-bottom: 49px;
        border-bottom: 1px solid #dedede;

        .acount-view-bottom-order-img {
          width: 160px;
          height: 160px;

          .col-md-3 {
            width: 100%;
            margin: 0;
            border: none;
            height: none;

            .thumb-container {
              width: 100%;
              // height: 317px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }
        }

        .acount-view-bottom-order-img-right {
          width: 1100px;
          display: flex;
          justify-content: space-between;

          margin-left: 15px;

          .dropHammerPrice {
            width: 700px;
          }

          p {
            margin-bottom: 10px;
          }

          .co-n {
            font-size: 18px;
            color: #333;
            font-weight: 400;
          }

          .my-price-9 {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            margin-bottom: 0.052083rem;
            display: inline-block;

            &:hover {
              text-decoration: underline;
            }
          }

          .cda156-a-1 {
            color: #cda156;
            text-decoration: underline;
            padding-left: 15px;
            font-size: 16px;
          }

          .qx-or-wy {
            font-size: 16px;
            font-weight: 500;
            line-height: 158px;
          }
        }
      }

      .acount-view-bottom-order-count {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 30px;
        align-items: center;
        height: 84px;

        p {
          margin-bottom: 0;
        }

        .jsmx-price {
          font-size: 20px;
          color: #6e5121;
          font-weight: 400;
          text-decoration: underline;
        }

        .count-price-2 {
          margin: 0 30px;

          .hj333 {
            color: #333;
            font-size: 18px;
          }

          .hj-rmb {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }


    }
    .jsmx-count {
        display: inline-block;
        width: 200px;
        color: #6e5121;
        font-weight: 600;
        font-size: 18px;
        opacity: 0.69;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        text-align: center;
        line-height: 44px;
      }

      .jsmx-count-coc {
        color: #999999;
        background: #dedede;
        border: none;
      }
    .pagination {
      margin-top: 20px;
      text-align: center;
    }

    .dialog-1 {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      color: #333;
      font-weight: 400;
      padding-bottom: 20px;

      .dialog-1-1 {
        .paddingRight {
          padding-right: 10px;
        }

        .font16 {
          font-size: 16px;
          color: #999;
        }

        .paddingLeft {
          text-decoration: underline;
          color: #cda156;
          padding-left: 10px;
        }
      }

      i {
        font-size: 20px;
        padding-right: 5px;
      }
    }

    .border-1 {
      border-bottom: 1px solid #dedede;
    }

    .border-2 {
      border-bottom: 1px solid #dedede;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .border-3 {
      border-bottom: 1px solid #dedede;
      font-weight: 600;

      margin-bottom: 20px;
    }

    .dialog-big {
      display: flex;
      justify-content: space-between;

      .dialog-left {
        font-size: 20px;
        color: #333;
        font-weight: 400;

        .dialog-1-1 {
          padding-bottom: 20px;
          text-align: left;

          .paddingRight {
            padding-right: 10px;
          }

          .font16 {
            font-size: 16px;
            color: #999;
          }

          .paddingLeft {
            text-decoration: underline;
            color: #cda156;
            padding-left: 10px;
          }
        }

        i {
          font-size: 20px;
          padding-right: 5px;
        }
      }
    }

    .dialog-1-text {
      display: flex;
      font-size: 16px;
      color: #333;

      p {
        margin-bottom: 0;
      }

      .biy-text {
        color: #e8a738;
      }

      .biy-text-2 {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
    }

    .payCode {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 256px;
      }
    }

    .itemized {
      .text {
        padding-bottom: 10px;
        font-size: 20px;
        color: #333;
        font-weight: 400;
      }

      .itemized-start-time {
        padding-bottom: 16px;
      }

      .middle {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        // padding: 10px 0 20px 0;
        margin-bottom: 10px;

        .middle-top {
          border-bottom: 1px solid #dedede;
          padding-bottom: 20px;
          padding-top: 10px;

          .middle-top-1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .text-1 {
              font-size: 20px;
              font-weight: 600;
              color: #333;
            }

            .text-1:hover {
              text-decoration: underline;
            }

            .block {
              display: block;
              border-radius: 2px;
              border: 1px solid #cd5656;
              font-size: 14px;
              color: #cd5656;
              padding: 2px 3px;
            }
          }

          .middle-top-2 {
            display: flex;
            align-items: center;

            .middle-img {
              width: 104px;
              // height: 104px;
              line-height: 104px;
              border-radius: 8px;

              img {
                width: 100%;
              }
            }

            .middle-txt {
              margin-left: 10px;

              p {
                margin-bottom: 0;
                font-size: 20px;
                color: #333;
                font-weight: 400;
                padding-top: 3px;
              }
            }
          }
        }

        .middle-top:last-child {
          border-bottom: none;
        }
      }

      .bottom {
        p {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-size: 20px;
          color: #333;
        }
      }

      .pay-money-5 {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 20px;
          color: #333;
          font-weight: 600;
        }
      }

      .pay-money-5:last-child {
        border: none;
      }
    }
  }
</style>
