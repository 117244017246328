var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quality-details"},[_c('div',{staticClass:"acount-view-bottom"},[_c('div',{staticClass:"acount-view-bottom-order-bottom"},[_c('div',{staticClass:"acount-view-bottom-order-img"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"thumb-container"},[_c('div',{staticClass:"thumb"},[_c('router-link',{attrs:{"target":"_blank","to":{
                  path: '/productdetails',
                  query: { productdetailsId: _vm.getPhaseReportDetailData.itemId }
                }}},[_c('img',{attrs:{"src":_vm.getPhaseReportDetailData.itemImg,"alt":""}})])],1)])])]),_c('div',{staticClass:"acount-view-bottom-order-img-right"},[_c('div',{staticClass:"dropHammerPrice"},[_c('router-link',{staticClass:"my-price-9",attrs:{"target":"_blank","to":{
              path: '/productdetails',
              query: { productdetailsId: _vm.getPhaseReportDetailData.itemId }
            }}},[_vm._v(" "+_vm._s(_vm.getPhaseReportDetailData.itemName)+" ")]),_c('router-link',{staticClass:"co-n",staticStyle:{"display":"block","margin-bottom":"0.052083rem"},attrs:{"target":"_blank","to":{
              path: '/auctionhousedetails',
              query: {
                auctionhousedetailsid: _vm.getPhaseReportDetailData.auctionHouseId
              }
            }}},[_vm._v(" 拍卖行："+_vm._s(_vm.getPhaseReportDetailData.auctionHouseName)+" ")]),_c('p',{staticClass:"co-n"},[_vm._v(" 开拍时间："+_vm._s(_vm.getPhaseReportDetailData.updateTime)+" ")])],1)])])]),_c('div',{staticClass:"details-middle"},[_c('div',{staticClass:"account-for"},[_c('p',{staticClass:"text-1"},[_vm._v("品相说明:")]),_c('p',{staticClass:"text-2"},[_vm._v(" "+_vm._s(_vm.getPhaseReportDetailData.phaseReport)+" ")])]),_c('div',{staticClass:"quality-img"},[_c('p',{staticClass:"text-1"},[_vm._v("细节图:")]),_c('div',{staticClass:"detail-drawing"},[_c('viewer',{staticStyle:{"display":"flex","width":"100%"},attrs:{"images":_vm.image}},_vm._l((_vm.image),function(item,index){return _c('div',{key:index,staticClass:"acount-view-bottom-order-img"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"thumb-container"},[_c('div',{staticClass:"thumb"},[_c('a',{attrs:{"href":"javascript:;"}},[_c('img',{attrs:{"src":item+_vm.imgSize(480),"data-source":item,"alt":""}})])])])])])}),0)],1)])]),_c('div',{staticClass:"quality-bottom"},[_c('p',{staticClass:"text-1"},[_vm._v("补充需求:")]),_c('div',{staticClass:"supplements"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.getPhaseReportDetailData.otherRequirements),expression:"getPhaseReportDetailData.otherRequirements"}],staticClass:"textarea-t",attrs:{"placeholder":"如需补充品相报告资料在此提交需求。"},domProps:{"value":(_vm.getPhaseReportDetailData.otherRequirements)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getPhaseReportDetailData, "otherRequirements", $event.target.value)}}}),_c('div',{staticClass:"btn-tt"},[_c('button',{on:{"click":function($event){return _vm.supplementary()}}},[_vm._v("提交补充需求")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }